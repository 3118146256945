<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  let isOpenNav = false;

  const toggleState = (e) => {
    e.preventDefault();
    isOpenNav = !isOpenNav;
    dispatch('openNavState', {
      state: isOpenNav
    });
  }
</script>

<div class="nav-toggle md:flex md:justify-end">
  <button on:click={toggleState} id="navToggleBtn" class="block w-6 h-6" aria-controls="mainNavigation" aria-expanded="{isOpenNav}">
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" height="40" width="40" viewBox="0 0 40 40" class="close-icon">
    <path d="m32.241 6.246-12.247 12.34L7.655 6.338l-1.408 1.42 12.339 12.247-12.247 12.34 1.42 1.409 12.247-12.34 12.339 12.248 1.408-1.42-12.339-12.246 12.247-12.34z"/>
  </svg>
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" height="40" width="40" viewBox="0 0 40 40" class="hamburger-icon">
    <path d="M0 7h40v2H0zm0 12h40v2H0zm0 12h40v2H0z"/>
  </svg>
  </button>
</div>

<style lang="scss">
  button {
    @apply relative;
    .hamburger-icon, .close-icon {
      @apply absolute top-0 left-0 w-full h-full transition-opacity duration-200 ease-in-out;
    }
    .hamburger-icon { @apply opacity-100; }
    .close-icon { @apply opacity-0; }
    &[aria-expanded="true"] {
      .hamburger-icon { @apply opacity-0; }
      .close-icon { @apply opacity-100; }
    }
  }
</style>
