<script lang="ts">
  import NavToggle from "./NavToggle.svelte";
  import Navigation from "./Navigation.svelte";

  let openNavState = false;
  const handleOpenNavState = (event) => {
    openNavState = event.detail.state;
  }

</script>

<NavToggle on:openNavState={handleOpenNavState} />
<Navigation {openNavState} />
