<script lang="ts">
  export let target;

  const audioFile = new Audio(target.getAttribute('data-audio'));
  const icons = {
    play: 'assets/player/png/play.png',
    pause: 'assets/player/png/pause.png',
    volume: 'assets/player/png/volume.png',
    volumeMuted:'assets/player/png/volume_muted.png',
    rewind:'assets/player/png/fast_rewind.png',
    replay:'assets/player/png/replay.png'
  }

  let isPlaying = false;
  let isMuted = false;
  let totalTrackTime;
  let totalTimeDisplay = "loading...";
  let currentTimeDisplay = "0:00:00";
  let timePos = 0;
  let progress;
  let trackTimer;
  let progressBar;

  audioFile.onloadedmetadata = () => {
    totalTrackTime = audioFile.duration;
    updateTime();
  }

  const updateTime = () => {
    timePos = audioFile.currentTime;
    progress = audioFile.currentTime * 100 / totalTrackTime;
    progressBar.style.backgroundImage = 'linear-gradient(to right, #333333 ' + progress + '%, #EEEEEE ' + progress + '%)';

    let currHrs:any  = Math.floor((audioFile.currentTime / 60) / 60);
    let currMins:any = Math.floor(audioFile.currentTime / 60);
    let currSecs:any = Math.floor(audioFile.currentTime - currMins * 60);

    let durHrs:any  = Math.floor( (totalTrackTime / 60) / 60 );
    let durMins:any = Math.floor( (totalTrackTime / 60) % 60 );
    let durSecs:any = Math.floor(totalTrackTime - (durHrs*60*60) - (durMins * 60));

    if(currSecs < 10) currSecs = `0${currSecs}`;
    if(durSecs < 10) durSecs   = `0${durSecs}`;
    if(currMins < 10) currMins = `0${currMins}`;
    if(durMins < 10) durMins   = `0${durMins}`;

    currentTimeDisplay = `${currMins}:${currSecs}`;
    totalTimeDisplay = `${durMins}:${durSecs}`;

    if (audioFile.ended) toggleTimeRunning();
  }

  const changeTime = (e) => {
    timePos = e.target.value;
    if (timePos > audioFile.currentTime) audioFile.currentTime += (timePos - audioFile.currentTime);
    else if (timePos < audioFile.currentTime) audioFile.currentTime -= (audioFile.currentTime - timePos);
    else audioFile.currentTime = timePos;
  }

  const toggleTimeRunning = () => {
    if (audioFile.ended) {
      isPlaying = false;
      clearInterval(trackTimer);
    }
    else {
      trackTimer = setInterval(updateTime, 100);
    }
  }

  const toggleMuteAudio = () => {
    isMuted = !isMuted;
    audioFile.volume = (isMuted) ? 0 : 1;
    console.log(audioFile.volume);
  }

  const replayAudio = () => {
    isPlaying = false;
    audioFile.pause();
    audioFile.currentTime = 0;
  }

  const pausePlayAudio = () => {
    if (audioFile.paused) {
      toggleTimeRunning();
      audioFile.play();
      isPlaying = true;
    }
    else {
      toggleTimeRunning();
      audioFile.pause();
      isPlaying = false;
    }
  }
</script>

<div class="player">
  <button id="play" class="play">
    <img src="{isPlaying ? icons.pause: icons.play}" alt="play" on:click={pausePlayAudio}/>
  </button>
  <div class="progress">
    <input type="range"
     id="progressRange"
     name="vol" min="0"
     max="{totalTrackTime}"
     value="{timePos}"
     on:input={changeTime}
     bind:this={progressBar}
    >
  </div>
  <div class="time">
    <span class="progress-time">{currentTimeDisplay}</span>/<span class="track-duration">{totalTimeDisplay}</span>
  </div>
  <button id="mute" class="mute">
    <img src="{isMuted ? icons.volumeMuted : icons.volume}" alt="mute" on:click={toggleMuteAudio} class:muted={isMuted}/>
  </button>
  <button id="rewind" class="replay">
    <img src="{icons.replay}" alt="replay" on:click={replayAudio} />
  </button>
</div>

<style lang="scss">
  .player {
    @apply my-6 last:mb-0 py-2 border-t border-b border-black max-w-[20rem] flex items-center;
  }
  .play {
    @apply block w-8 h-8 rounded-full flex items-center justify-center;
    img {
      @apply w-5 h-5;
    }
  }
  .time {
    @apply mr-1 font-light;
    span {
      @apply inline-block w-10 last:pl-1;
    }
  }
  .replay {
    @apply mr-1 block w-5 h-5 rounded-full flex items-center justify-center;
    img {
      @apply w-4 h-4;
    }
  }
  .mute {
    @apply block w-5 h-5 rounded-full flex items-center justify-center;
    img {
      @apply w-5 h-5;
    }
  }
  .progress {
    @apply flex-1 rounded overflow-hidden ml-2 mr-4 w-full flex items-center overflow-visible;

    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      outline: none;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width:7px;
        height: 7px;
        border-radius: 4px;
        background: #333333;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width:7px;
        height: 7px;
        border-radius: 4px;
        background: #333333;
        cursor: pointer;
      }
    }
  }
</style>
