<script lang="ts">
  import TailwindCSS from "./TailwindCSS.svelte";
  import GlobalCSS from "./Global.svelte";
  import Navbar from "./components/Navbar.svelte";
  import Player from "./components/Player.svelte";
  import TranscriptToggle from "./components/TranscriptToggle.svelte";

  // Navbar
  if (document.querySelector('#navBar')) {
    let navbar = document.getElementById('navBar');
    new Navbar({
      target: navbar
    });
  }

  // Transcript
  if (document.querySelector('#transcriptToggle')) {
    let transcriptToggle = document.getElementById('transcriptToggle');
    new TranscriptToggle({
      target: transcriptToggle,
      props: {
        target: transcriptToggle
      }
    });
  }

  // Player Component
  if (document.querySelectorAll('.audio-player').length !== 0) {
      Array.from(document.querySelectorAll('.audio-player')).forEach((node) => new Player({
          target: node,
          props: {
              target: node
          }
      }));
  }
</script>
<TailwindCSS />
<GlobalCSS />
