<script>
	import { slide } from 'svelte/transition';

  export let target;
  const toggle = target.querySelector('span');
  const transcriptContainer = document.querySelector('#transcriptContainer');
	const transcriptContainerCnt = transcriptContainer.innerHTML;

	let isOpen = false;

	const tsToggle = () => {
		isOpen = !isOpen;
	}

	toggle.addEventListener("click", tsToggle);
	transcriptContainer.remove();

</script>
{#if isOpen}
  <div transition:slide class="text-mediumgrey italic pt-4 antialiased">
    {@html transcriptContainerCnt}
  </div>
{/if}
